import React, { useContext } from "react"
import { ModalConsumer, ModalContext } from '../components/modalContext'
import DomainPrices from '../components/domainPrices'
import Close from "../assets/img/icons/multiply.svg"

export default function DomainPricesModal() {
    const id = 'domain-prices-modal';
    const modalContext = useContext(ModalContext);

    function closeModal() {
        modalContext.closeModal();
    }

    return (
        <ModalConsumer>
            {context =>
                <div className={`modal-wrapper ${id}-wrapper ${context.modalOpen && context.activeModal === id ? 'modal-open' : ''}`}>
                    <div aria-label="Close" aria-hidden="true" role="button" className="overlay" onClick={closeModal}></div>
                    <div className={`modal ${id}`}>
                        <button aria-label="Close" className="close-btn" onClick={closeModal}><Close className="icon-white" /></button>
                        <DomainPrices />
                    </div>
                </div>
            }
        </ModalConsumer>
    )
}