import React from "react";

export default function DomainTdl () {
    return (
        <div className="card-body p-4">
            <h2 className="text-center mb-3">Domain Name Prices</h2>
            <p className="text-center">Annual Fees:</p>
            <div className="flex justify-between domain-prices">
                <div className="w-6/12 left">
                    <ul className="list-none text-left">
                        <li><span className="domain-name cl-primary text-dark">.co.za</span> <span>R150.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.org.za</span> <span>R150.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.com</span> <span>R310.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.org</span> <span>R400.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.net</span> <span>R400.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.info</span> <span>R470.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.biz</span> <span>R440.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.cc</span> <span>R1,070.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.mobi</span> <span>R450.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.co</span> <span>R740.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.tv</span> <span>R1,230.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.co.uk</span> <span>R580.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.de</span> <span>R290.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.eu</span> <span>R260.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.africa</span> <span>R710.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.capetown</span> <span>R400.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.durban</span> <span>R400.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.joburg</span> <span>R400.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.accountant</span> <span>R350.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.adult</span> <span>R2,200.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.art</span> <span>R350.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.attorney</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.beer</span> <span>R750.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.business</span> <span>R270.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.careers</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.catering</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.city</span> <span>R570.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.click</span> <span>R300.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.clothing</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.cloud</span> <span>R590.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.coffee</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.dance</span> <span>R620.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.deals</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.design</span> <span>R1,210.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.digital</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.doctor</span> <span>R2,170.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.dog</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.earth</span> <span>R590.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.education</span> <span>R570.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.energy</span> <span>R2,170.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.engineer</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.events</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.fashion</span> <span>R750.00</span></li>
                    </ul>
                </div>
                <div className="w-6/12 right">
                    <ul className="list-none text-left">
                        <li><span className="domain-name cl-primary text-dark">.film</span> <span>R2,150.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.finance</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.fit</span> <span>R750.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.fitness</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.flights</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.fun</span> <span>R410.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.games</span> <span>R510.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.group</span> <span>R350.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.global</span> <span>R1,800.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.health</span> <span>R1,850.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.holiday</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.lawyer</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.legal</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.life</span> <span>R780.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.love</span> <span>R750.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.media</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.money</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.network</span> <span>R570.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.news</span> <span>R620.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.online</span> <span>R540.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.party</span> <span>R110.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.photos</span> <span>R570.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.pizza</span> <span>R1,260.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.properties</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.racing</span> <span>R190.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.recipes</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.rentals</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.restaurant</span> <span>R1,240.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.rocks</span> <span>R350.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.school</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.science</span> <span>R190.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.shop</span> <span>R1,000.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.site</span> <span>R460.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.social</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.software</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.solutions</span> <span>R570.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.space</span> <span>R270.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.store</span> <span>R700.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.studio</span> <span>R620.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.tech</span> <span>R810.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.travel</span> <span>R2,710.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.website</span> <span>R270.00</span></li>
                        <li><span className="domain-name cl-primary text-dark">.wine</span> <span>R1,240.00</span></li>
                    </ul>
                </div>
            </div>
        </div>         
    )
}