import React from "react"
import MyLink from "../components/link"
import Scrollspy from "react-scrollspy"
import { toSlug } from "./utils"

export default function SubNav({ title, nav }) {
    const offset = -110; // header + subnav
    const isBrowser = typeof window !== `undefined`;

    const navSlugs = [];
    nav.map((el) => {
        return navSlugs.push(toSlug(el));
    });

    const link = (isBrowser) ? window.location.pathname : '/';

    function scrollTo(e) {
        e.preventDefault();
        if(isBrowser) {
            const id = e.target.href.split('#')[1];
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top + window.pageYOffset + offset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }

    return (
        <nav id="subnav">
            <div className="container flex flex-row justify-start">
                <ul className="menu">
                    <li className="level-1">Services</li>
                    <li className="level-separator">></li>
                    <li className="level-2"><MyLink to={link}>{title}</MyLink></li>
                    <li className="level-separator">></li>
                </ul>
                <Scrollspy offset={offset} className="menu" items={navSlugs} currentClassName="active" scrolledPastClassName="scrolled-past">
                    {nav.map((el, ind) => {
                        return (
                            <li key={ind}><a onClick={scrollTo} href={'#'+toSlug(el)}>{ind+1}. {el}</a></li>
                        );
                    })}
                </Scrollspy>
            </div>
        </nav>
    )
}