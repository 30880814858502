import React, { useContext } from "react"
import Icon1 from "../assets/img/icons/networking-manager.svg"
import Icon2 from "../assets/img/icons/maintenance.svg"
import Icon3 from "../assets/img/icons/new/email.svg"
import Icon4 from "../assets/img/icons/big-parcel.svg"
import Icon5 from "../assets/img/icons/rocket.svg"
import Icon6 from "../assets/img/icons/new/transfers.svg"
import Introduction from "../components/intro"
import FindDomain from "../components/findDomain"
import SignUpHosting from "../components/signUpHosting"
import HostingPackages from "../components/hostingPackages"
import Artwork from "../assets/img/artwork/artwork-09.svg"
import SubNav from "../components/subNav"
import { ModalContext } from "../components/modalContext"
import TransferDomainModal from "../components/transferDomainModal"
import DomainPricesModal from "../components/domainPricesModal"

export default function Page() {
    const modalContext = useContext(ModalContext);

    return (
        <div className="service-page">
            <Introduction Artwork={Artwork}>
                <h1><span>Hosting</span> Services.</h1>
                <p className="text-larger"><strong>Take the hassle out of hosting.</strong><br />We have a variety of affordable solutions available.</p>
                <p className="mt-12 mb-0">
                    <a href="#packages" className="btn btn-lg btn-blue">Hosting Packages</a>
                    {/*
                    <a href="#find-a-domain" className="btn btn-lg btn-blue">Find a Domain</a>
                    */}
                </p>
            </Introduction>

            <SubNav
                title="Hosting Services"
                nav={['Offering', 'Packages',  'Features', 'Sign Up']} // , 'Find a Domain'
            />

            {/* Offering */}
            <div id="offering" className="component top-gradient-darker double-padding">
                <div className="container">
                    <h3 className="text-center font-normal cl-white">We can assist with:</h3>

                    <div className="grid icons-50 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon2 className="icon-white" />
                            <h3 className="cl-primary mt-5">Cloud server management</h3>
                            <p>Provisioning and management of cloud web servers. Security, performance &amp; redundancy sorted.</p>
                        </div>
                        <div className="mt-8">
                            <Icon3 className="icon-white" />
                            <h3 className="cl-primary mt-5">Email hosting</h3>
                            <p>We can manage mailboxes and help everyone get set up on all their devices, outsource your email worries.</p>
                        </div>
                        <div className="mt-8">
                            <Icon4 className="icon-white" />
                            <h3 className="cl-primary mt-5">Framework installation</h3>
                            <p>Want to build your own website?<br />We can set you up.</p>
                        </div>
                        <div className="mt-8">
                            <Icon5 className="icon-white" />
                            <h3 className="cl-primary mt-5">Deployments</h3>
                            <p>Need something deployed somewhere? We can assist with deployments for various frameworks.</p>
                        </div>
                        <div className="mt-8">
                            <Icon6 className="icon-white" />
                            <h3 className="cl-primary mt-5">Transfers &amp; migrations</h3>
                            <p>We can assist with domain transfers and all sorts of migrations. <button onClick={() => modalContext.openModal('contact-modal')} className="cl-green">Get in touch</button> if you need help moving.</p>
                        </div>
                        <div className="mt-8">
                            <Icon1 className="icon-white" />
                            <h3 className="cl-primary mt-5">Custom hosting solutions</h3>
                            <p><button onClick={() => modalContext.openModal('contact-modal')} className="cl-green">Get in touch</button> if you have requirements that are not covered by our hosting packages, we can assist.</p>
                        </div>
                    </div>

                    <p className="text-center mt-6"><button onClick={() => modalContext.openModal('contact-modal')} className="btn btn-lg btn-green">Get in Touch</button></p>
                </div>
            </div>

            {/* Packages */}
            <HostingPackages />

            {/* Payment Options */}
            <div className="component double-padding-bottom pt-0 px-10 lg:px-0">
                <h4 className="cl-primary mt-0 text-center">Payment options:</h4>
                <ul className="md:flex flex-row justify-between w-full lg:w-2/5 mx-auto text-smaller">
                    <li><span className="cl-primary text-larger">&#10687;</span> Monthly EFT</li>
                    <li><span className="cl-primary text-larger">&#10687;</span> Monthly Debit Order</li>
                    <li><span className="cl-primary text-larger">&#10687;</span> Annual EFT</li>
                </ul>
            </div>

            {/* Features */}
            <div id="features" className="component double-padding bg-darker">
                <div className="container">
                    <div className="w-full lg:w-5/6 flex flex-wrap mx-auto">
                        <div className="w-full mx-auto lg:w-1/2">
                            <h4 className="cl-primary mt-0">All <strong>Web Hosting</strong> packages include:</h4>
                            <div className="text-border-left-green">
                                <p className="h4 h4-smaller">99.9% uptime guarantee</p>
                                <p className="h4 h4-smaller">Daily backups</p>
                                <p className="h4 h4-smaller">Deployment assistance</p>
                            </div>
                        </div>
                        <div className="w-full mx-auto lg:w-1/2 mt-10 lg:mt-0">
                            <h4 className="cl-primary mt-0">All <strong>Email Hosting</strong> packages include:</h4>
                            <div className="text-border-left-blue">
                                <p className="h4 h4-smaller">Webmail access</p>
                                <p className="h4 h4-smaller">Spam protection</p>
                                <p className="h4 h4-smaller">Setup assistance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Find a Domain */}
            {/*
            <FindDomain />
            */}

            {/* Sign up for hosting */}
            <SignUpHosting />

            {/* MODAL - Transfer Domain */}
            <TransferDomainModal />

            {/* MODAL - Domain Prices */}
            <DomainPricesModal />
        </div>
    )
}
