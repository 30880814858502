import React, { useState } from "react";
import Yes from "../assets/img/icons/yes.svg";
import No from "../assets/img/icons/multiply.svg";
import DownArrow from "../assets/img/icons/expand-arrow.svg";

export default function HostingPackages() {
  const [newPrice, setNewPrice] = useState(150);

  const prices = [150, 300, 450, 600, 850];

  function handleSizeChange(event) {
    setNewPrice(prices[event.target.value]);
  }

  return (
    <div id="packages" className="component relative gradient-right-blue">
      <div className="xl:container mx-auto px-3">
        <h2 className="text-center">
          Hosting Packages
          <span className="symbol">
            <span></span>
          </span>
        </h2>
        <div className="w-full mx-auto xl:w-5/6">
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="card card-border-top card-purple">
              <h3 className="text-center mb-2">Email Only</h3>
              <p className="cl-primary text-larger text-center mb-0">
                R50 per month
              </p>
              <p className="cl-footer text-smaller text-center">excl. VAT</p>
              <table className="card-table">
                <tbody>
                  <tr>
                    <th width="50%">SSD Storage</th>
                    <td width="50%">5GB</td>
                  </tr>
                  <tr>
                    <th>Website Hosting</th>
                    <td className="svg-no">
                      <No />
                    </td>
                  </tr>
                  <tr>
                    <th>Email Hosting</th>
                    <td>
                      <Yes />
                    </td>
                  </tr>
                  <tr>
                    <th>PHP & MySQL</th>
                    <td className="svg-no">
                      <No />
                    </td>
                  </tr>
                  <tr>
                    <th>Web Server</th>
                    <td className="svg-no">
                      <No />
                    </td>
                  </tr>
                  <tr>
                    <th>Support</th>
                    <td>Email only</td>
                  </tr>
                </tbody>
              </table>
              <p className="text-center">
                <a href="#sign-up" className="btn">
                  Sign Up
                </a>
              </p>
            </div>
            <div className="card card-border-top card-blue">
              <h3 className="text-center mb-2">Web Only</h3>
              <p className="cl-primary text-larger text-center mb-0">
                R100 per month
              </p>
              <p className="cl-footer text-smaller text-center">excl. VAT</p>
              <table className="card-table">
                <tbody>
                  <tr>
                    <th>SSD Storage</th>
                    <td>5GB</td>
                  </tr>
                  <tr>
                    <th>Website Hosting</th>
                    <td>
                      <Yes />
                    </td>
                  </tr>
                  <tr>
                    <th>Email Hosting</th>
                    <td className="svg-no">
                      <No />
                    </td>
                  </tr>
                  <tr>
                    <th>PHP & MySQL</th>
                    <td>
                      <Yes />
                    </td>
                  </tr>
                  <tr>
                    <th>Web Server</th>
                    <td>Nginx or Apache</td>
                  </tr>
                  <tr>
                    <th>Support</th>
                    <td>Email only</td>
                  </tr>
                </tbody>
              </table>
              <p className="text-center">
                <a href="#sign-up" className="btn">
                  Sign Up
                </a>
              </p>
            </div>
            <div className="card card-border-top card-green">
              <h3 className="text-center mb-2">Web &amp; Email</h3>
              <p className="cl-primary text-larger text-center mb-0">
                R{newPrice} per month
              </p>
              <p className="cl-footer text-smaller text-center">excl. VAT</p>
              <table className="card-table">
                <tbody>
                  <tr>
                    <th>SSD Storage</th>
                    <td>
                      <div className="form-field">
                        <DownArrow className="down-arrow" />
                        <select
                          onChange={handleSizeChange}
                          name="storage"
                          className="form-select"
                        >
                          <option value="0">10GB</option>
                          <option value="1">25GB</option>
                          <option value="2">50GB</option>
                          <option value="3">75GB</option>
                          <option value="4">100GB</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Website Hosting</th>
                    <td>
                      <Yes />
                    </td>
                  </tr>
                  <tr>
                    <th>Email Hosting</th>
                    <td>
                      <Yes />
                    </td>
                  </tr>
                  <tr>
                    <th>PHP & MySQL</th>
                    <td>
                      <Yes />
                    </td>
                  </tr>
                  <tr>
                    <th>Web Server</th>
                    <td>Nginx or Apache</td>
                  </tr>
                  <tr>
                    <th>Support</th>
                    <td>Email &amp; phone</td>
                  </tr>
                </tbody>
              </table>
              <p className="text-center">
                <a href="#sign-up" className="btn">
                  Sign Up
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="top-gradient-darker"></div>
    </div>
  );
}
