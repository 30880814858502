import React, { useState } from "react"
import { useForm } from "react-hook-form/dist/index"
import InputText from "../fields/text"
import InputSelect from "../fields/select"
import axios from "axios"

export default function SignUpHosting() {
    const formType = 'Hosting Sign Up';
    const formUrl = 'https://traktion.app/forms/send/';
    const successMsg = '<strong>Your form was sent successfully.</strong><br />We\'ll respond as soon as possible.';

    const { register, handleSubmit, errors } = useForm(); // watch
    const [ sending, setSending ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    function onSubmit(data) {
        setSending(true);
        data.type = formType;
        axios.post(formUrl, data).then(res => {
            setSending(false);
            if(res.data.success === true) {
                setSuccess(true);
            } else {
                alert('Something went wrong, please email us - info@artificial.studio');
                console.log(res);
            }
        });
    }

    return (
        <div id="sign-up" className="component double-padding vh-100 gradient-left-purple pb-32">
            <h2 className="text-center mb-0">Sign Up for Hosting<span className="symbol symbol-purple"><span></span></span></h2>
            <div className="container flex flex-wrap pt-12 justify-center">
                <div className="w-full md:w-4/12">
                    {success &&
                        <>
                            <h3 className="text-center">Thank you!</h3>
                            <p className="cl-primary text-center" dangerouslySetInnerHTML={{__html: successMsg}}></p>
                        </>
                    }

                    {!success &&
                        <>
                            <form encType="multipart/formdata" method="POST" onSubmit={handleSubmit(onSubmit)}>
                                <InputText
                                    className="w-full"
                                    label="Name"
                                    name="first_name"
                                    error="Please enter your name"
                                    validate={register({required: true})}
                                    errors={errors}
                                />

                                <InputText
                                    className="w-full"
                                    label="Email"
                                    name="email"
                                    error="Please enter a valid email address"
                                    validate={register({
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                        }
                                    })}
                                    errors={errors}
                                />

                                <InputText
                                    className="w-full"
                                    label="Contact number"
                                    name="contact_number"
                                    errors={errors}
                                />

                                <InputSelect
                                    label="Payment Preference"
                                    name="payment-preference"
                                    error="Please select a payment preference"
                                    validate={register({required: true})}
                                    errors={errors}
                                >
                                    <option value="Monthly Debit Order">Monthly Debit Order</option>
                                    <option value="Monthly EFT">Monthly EFT</option>
                                    <option value="Annual EFT">Annual EFT</option>
                                </InputSelect>

                                <div className="form-field">
                                    <button disabled={sending} type="submit" className={`btn btn-lg btn-primary mt-4 ${sending ? 'disabled': ''}`}>
                                        {sending && <>Sending...</>}
                                        {!sending && <>Submit</>}
                                    </button>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>
            <div className="top-gradient-darker"></div>
        </div>
    )
}